<template>
  <div class="primary-block send-gift">
    <v-dialog/>

    <template v-if="!sent">
      <p class="price">6 000 ₸</p>
      <p class="success-text fw-bold text-center">
        {{$t('giftFriendInsurance')}}
      </p>
      <p class="secondary-text text-center">
        {{$t('giftFriendNumber')}}
      </p>
<!--      <input-->
<!--        v-model="phoneNumber"-->
<!--        type="tel"-->
<!--        class="secondary-input-field mb-3"-->
<!--      />-->
      <the-mask
          mask="+7 (###)-###-##-##"
          v-model="phoneNumber"
          type="tel"
          class="secondary-input-field mb-3"
          :masked="true"
      />
      <button class="primary-btn w-100" @click="sendGift()">{{$t('send')}}</button>
    </template>
    <template v-else>
      <div v-if="error">
        <p class="text-center danger-text">{{ error }}</p>
      </div>
      <div v-else style="justify-content: center; display: flex;flex-direction: column;">
        <svg
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="mb-4"
          style="margin: 0 auto 1.5rem;"
        >
          <circle cx="24" cy="24" r="24" fill="#EAF6EF" />
          <path
            d="M34 17L20.6429 30L15 24.5"
            stroke="#2AA65C"
            stroke-width="4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <p class="success-text fw-bold text-center mb-4">
          {{$t('giftFriendSent')}}
        </p>
        <p class="secondary-text text-center">
          {{$t('giftFriendSmsSent')}}
        </p>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "SendGiftToFriend",
  props: {
    invoiceId: null,
  },
  data() {
    return {
      giftInfoShown: true,
      phoneNumber: "+7 (7",
      error: "",
      sent: false
    };
  },
  methods: {
    async sendGift() {
      if (!this.phoneNumber.match(/\+7\s\(7\d{2}\)-\d{3}-\d{2}-\d{2}/gi)) {
        this.$modal.show('dialog', {
          text: this.$i18n.t("incorrectPhoneNumber"),
          buttons: [{ title: 'OK', handler: () => this.$modal.hide('dialog') }]
        }, {class: 'error'});

        return;
      }

      const loader = this.$loading.show()

      try{
        const {data} = await window.axios.post("https://797.polisonline.kz/api/SmsToFriend",
            {
              Phone: this.phoneNumber,
              invoiceID: String(this.invoiceId),
            }
        );

        this.sent = true

        //Если успешно сохранен отзыв
        if (data.statusCode === 200) {
          // TODO stage = 21
          this.$modal.show('dialog', {
            text: this.$i18n.t("giftFriendSmsSent"),
            buttons: [{ title: 'OK', handler: () => this.$modal.hide('dialog') }]
          }, {class: 'success'});

        } else if (data.statusCode === 400) {
          //Если отзыв был ранее
          this.$modal.show('dialog', {
            text: this.$i18n.t('giftAlreadySent'),
            buttons: [{ title: 'OK', handler: () => this.$modal.hide('dialog') }]
          }, {class: 'error'});
        }
      } catch (e) {
        this.error = e?.response?.data?.message || e?.message
        this.$modal.show('dialog', {
          text: e?.response?.data?.message || e?.message,
          buttons: [{ title: 'OK', handler: () => this.$modal.hide('dialog') }]
        }, {class: 'error'});
      } finally {
        loader.hide();
      }
    },
  },

};
</script>

<style lang="scss" scoped>
@import "src/sass/variables";
.send-gift {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 300px;
  .price {
    font-size: 28px;
    text-align: center;
    color: $primary-color;
    text-decoration: line-through;
    text-decoration-color: #f9b000;
    text-decoration-thickness: 3px;
  }
}
</style>
